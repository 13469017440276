<template>
  <div id="lienholderGrid">
    <div @keyup.enter="search" class="panel">
      <h3 v-if="!isModal" class="title">Lienholder Account Maintenance</h3>

      <div>
        <div id="searchInputs">
          <v-text-field
            v-if="!isModal"
            clearable
            v-debounce:500="search"
            v-model="lienholderID"
            label="Account Number"
          />

          <v-text-field
            v-debounce:800="search"
            clearable
            :value="lienholder.customerName"
            @input="lienholder.customerName = $event.toUpperCase()"
            label="Name"
          />
        </div>

        <span v-if="!isModal">
          <vue-smarty-streets
            v-show="selectedResult != undefined"
            :uppercase="true"
            :addressObj="lienholder.address"
          ></vue-smarty-streets>

          <div id="buttonDiv">
            <v-btn
              color="green"
              v-show="
                selectedResult == undefined &&
                lienholder.customerName.length > 0
              "
              @click="selectResult()"
            >
              + Add
            </v-btn>
            <v-btn
              color="primary"
              v-show="selectedResult != undefined"
              @click="save"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              v-show="
                selectedResult != undefined ||
                lienholder.customerName != '' ||
                lienholderID != ''
              "
              @click="
                selectResult({}, true);
                search();
              "
            >
              Clear
            </v-btn>
          </div>
        </span>
      </div>
    </div>

    <div class="tableDiv">
      <p>Click on a result row to manage lien holder.</p>

      <div v-show="working" class="center">
        <h2>Loading...</h2>
      </div>

      <div class="bootstrap">
        <b-table
          sticky-header="45vh"
          :no-border-collapse="true"
          :show-empty="true"
          :fields="tableHeaders"
          head-variant="light"
          sort-icon-left
          responsive="sm"
          selectable
          select-mode="single"
          hover
          @row-clicked="item => selectResult(item)"
          :items="results"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vueSmartyStreets from "@/components/nonPageComponents/vue-smarty-streets";

export default {
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false
    },
    nameSet: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: {
    "vue-smarty-streets": vueSmartyStreets
  },
  data() {
    return {
      lienholderID: "",
      lienholder: {
        customerName: "",
        address: {
          address1: "",
          city: "",
          state: "",
          postalCode: ""
        }
      },
      tableHeaders: [
        {
          key: "accountNumber",
          sortable: true
        },
        {
          key: "customerName",
          label: "Name",
          sortable: true
        },
        {
          key: "address.address1",
          label: "Street Address",
          sortable: true
        },
        {
          key: "address.city",
          label: "City",
          sortable: true
        },
        {
          key: "address.state",
          label: "State",
          sortable: true
        },
        {
          key: "address.postalCode",
          label: "Zip",
          sortable: true
        }
      ],
      results: [],
      selectedResult: null,
      working: false
    };
  },
  methods: {
    selectResult(value = false, clear = false) {
      if (
        (this.selectedResult != undefined &&
          this.selectedResult.lienholderListID == value.lienholderListID) ||
        clear
      ) {
        this.selectedResult = null;
        this.lienholderID = "";
        this.lienholder = {
          customerName: "",
          address: {
            address1: "",
            city: "",
            state: "",
            postalCode: "",
            countyID: parseInt(this.$store.getters.countyId)
          }
        };
      } else if (!value) {
        this.selectedResult = {
          customerName: this.lienholder.customerName,
          address: {
            address1: "",
            city: "",
            state: "",
            postalCode: "",
            countyID: parseInt(this.$store.getters.countyId)
          }
        };
      } else if (this.isModal) {
        this.$emit("select", value);
      } else {
        this.lienholder = JSON.parse(JSON.stringify(value));
        this.selectedResult = value;
        if (typeof this.lienholder.accountNumber != "object") {
          this.lienholderID = this.lienholder.accountNumber;
        }
      }
    },
    async idSearch(id) {
      this.results = [];
      try {
        this.working = true;
        this.$api.searchLienByID(id, { 404: () => {} }).then(data => {
          if (data) {
            this.results.push(data);
          }
        });
      } finally {
        this.working = false;
      }
    },
    async search() {
      this.results = [];
      if (this.lienholderID != undefined && this.lienholderID != "") {
        this.idSearch(this.lienholderID);
      } else if (
        this.lienholder.customerName != undefined &&
        this.lienholder.customerName.length > 0
      ) {
        this.working = true;
        try {
          await this.$api
            .searchLienHolders(
              this.$store.getters.countyId,
              this.lienholder.customerName,
              { 404: () => {} }
            )
            .then(data => {
              this.working = false;
              if (data) {
                this.results = data;
              }
            });
        } catch (e) {
          this.working = false;
        }
      } else {
        this.results = [];
      }
    },
    async save() {
      if (this.lienholderID != undefined) {
        this.lienholder.accountNumber = this.lienholderID;
      }
      try {
        this.lienholder.address.countyID = parseInt(
          this.$store.getters.countyId
        );
        const data = await this.$api.saveLienholder(this.lienholder);
        this.selectResult({}, true);
        this.search();
        this.$store.dispatch("setGlobalAlertState", {
          title: "Success",
          description:
            "The lienholder was saved with an ID of: " + data.accountNumber,
          icon: "success"
        });
      } catch (e) {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Error",
          description: "There was a problem with the server!",
          icon: "error"
        });
      }
    }
  },
  mounted() {
    if (this.nameSet != "") {
      this.lienholder.customerName = this.nameSet;
    }
    this.search();
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/global-variables.scss";

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#lienholderGrid {
  overflow: hidden;
}
#searchInputs {
  display: flex;
  flex-direction: row;
}
#searchInputs > div {
  padding-right: 5px;
}
#resultsTable tr:hover {
  background-color: lightgray;
}
.selected {
  background-color: var(--button-background);
}
.inputDiv {
  margin-right: 5px;
}
#buttonDiv {
  display: flex;
  justify-content: flex-end;
}
#buttonDiv button {
  margin: 0;
}
</style>
