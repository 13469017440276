<template>
  <div class="lienholderMaintDiv">
    <lienholderGrid></lienholderGrid>
  </div>
</template>

<script>
import lienholderGrid from "@/components/nonPageComponents/lienholderGrid";

export default {
  components: {
    lienholderGrid
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped></style>
